import { FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import SuppliersCarousel from './SuppliersCarousel/SuppliersCarousel'
import HeroSection from './HeroSection'
import TrustedByLogos from './TrustedByLogos'
import SupplierCirclesCarousel from './SupplierCirclesCarousel'
import FeaturesBenefitsSection from './FeaturesBenefitsSection'
import CallToActionTwoColumns from './CallToActionTwoColumns/CallToActionTwoColumns'
import useRecommendedForYou from '../../services/recommendationsAPI/useRecommendedForYou'
import ProductCarousel from '../Carousels/ProductCarousel'
import { AnalyticsProductCarouselTypes } from '../../utils/types/analytics'

import styles from '../../styles/LandingPage/LandingPage.module.less'

const LandingPage: FC = () => {
  const { t } = useTranslation('landing')

  const {
    data: recentlyViewedProducts,
    triggerHomePageView,
  } = useRecommendedForYou({ pageSize: 60 })

  useEffect(() => {
    // Load recommended products on page load
    triggerHomePageView()
  }, [])

  return (
    <div className={`${styles['landing-page-main']} ${styles['with-hero-section-container']}`}>
      <HeroSection />
      <TrustedByLogos />
      <ProductCarousel
        carouselType={AnalyticsProductCarouselTypes.RecommendedForYou}
        productCardType="narrow"
        title={t('products:Recommended for you')}
        products={recentlyViewedProducts?.slice(0, 15)}
        className="margin-top-spacer-quadruple margin-bottom-spacer-quadruple"
      />
      <SupplierCirclesCarousel />
      <FeaturesBenefitsSection />
      <SuppliersCarousel />
      <ProductCarousel
        carouselType={AnalyticsProductCarouselTypes.RecommendedForYou}
        productCardType="narrow"
        title={t('products:Recommended for you')}
        products={recentlyViewedProducts?.slice(15, -1)}
        hasBrowseLink
      />
      <CallToActionTwoColumns title={t('landing:Ready to redefine the way you buy')} />
    </div>
  )
}

export default LandingPage
